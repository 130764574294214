/*
label{
    display: block;
     margin-bottom: 10px;
 }
*/


input[type=radio] {
   appearance: none;
   background-color: #fff;
   width: 15px;
   height: 15px;
   border: 2px solid #ccc;
   border-radius: 2px;
   display: inline-grid;
   place-content: center; 
 }

input[type=radio]::before {
   content: "";
   width: 10px;
   height: 10px;
   transform: scale(0);
   transform-origin: bottom left;
   background-color: #fff;
   clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
}

input[type=radio]:checked::before {
   transform: scale(1);
}
input[type=radio]:checked{
   background-color:   #0075FF;
   border: 2px solid #0075FF;
}

input[type=checkbox] {
    appearance: none;
    background-color: #fff;
    width: 15px;
    height: 15px;
    border: 2px solid #ccc;
    border-radius: 2px;
    display: inline-grid;
    place-content: center; 
  }
 
 input[type=checkbox]::before {
    content: "";
    width: 10px;
    height: 10px;
    transform: scale(0);
    transform-origin: bottom left;
    background-color: #fff;
    clip-path: polygon(13% 50%, 34% 66%, 81% 2%, 100% 18%, 39% 100%, 0 71%);
 }
 
 input[type=checkbox]:checked::before {
    transform: scale(1);
 }
 input[type=checkbox]:checked{
    background-color:   #0075FF;
    border: 2px solid #0075FF;
 }

 .checkbox.error-checkbox input[type="checkbox"]:checked + label::after  {
   content: "\f00d";
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
   background-color: #d9534f;
   border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
   color: #fff;
}

 #vertical{
   writing-mode: vertical-rl;
   text-orientation: upright;
 }
